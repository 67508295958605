import React from "react"
import styled from "styled-components"

import aboutBackgroundImage from 'assets/images/about/arkeo-about-app-background.png'

const Section = styled.section`
	background-color: #171717;
	margin-top: -82px;

	@media(max-width: 855px) {
		margin-top: 0px;
  }
`

const Content = styled.div`
	max-width: 1440px;
	margin: 0 auto;
	padding: 0 140px;

	@media(max-width: 855px) {
		padding: 0 60px;
  }

	@media(max-width: 455px) {
		padding: 0 20px;
  }
`

const Image = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	background-image: url(${aboutBackgroundImage});
	background-size: cover;
	background-position: right;
	background-repeat: no-repeat;
	min-height: 635px;

	@media(max-width: 855px) {
		min-height: 400px;
  }
`

const Title = styled.h1`
	background: -webkit-linear-gradient(#56f4f4, #e65551);
	-webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 200px;
  font-family: ${({ theme }) => theme.font.family.bebas};
	letter-spacing: 5px;
	font-weight: 500;
	margin: 0;
	
	@media(max-width: 1189px) {
		font-size: 160px;
		line-height: 130px;
		margin-top: -50px;
		margin-bottom: 20px;
  }

	@media(max-width: 1010px) {
		font-size: 120px;
		line-height: 110px;
		margin-top: -50px;
  }

	@media(max-width: 855px) {
		margin-top: -50px;
  }

	@media(max-width: 693px) {
		font-size: 80px;
		line-height: 70px;
		margin-top: -30px;
  }

	@media(max-width: 335px) {
		font-size: 80px;
  }
`

const Description = styled.p`
	max-width: 250px;
	line-height: 33px;
	text-transform: uppercase;
	font-family: ${({ theme }) => theme.font.family.circular};
	font-size: 26px;
	font-weight: 400;
	color: white;
	letter-spacing: -1px;
	margin-top: 0;
`

const InfoText = styled.h3`
	color: white;
	font-size: 40px;
	font-family: ${({ theme }) => theme.font.family.bebas};
	font-weight: 400;
	margin-top: 0;
`

const FirstSection = () => (
	<Section>
		<Content>
			<Image>
				<Title>
					I track, I win
				</Title>
				<Description>
					Tracking your mental fitness
				</Description>
				<InfoText>
					COMING SOON
				</InfoText>
			</Image>
		</Content>
	</Section>
)

export default FirstSection