import React from "react"
import styled from "styled-components"

const Section = styled.section`
	background-color: white;
	padding: 200px 140px 100px 140px;

	@media(max-width: 520px) {
		padding-left: 40px;
		padding-right: 40px
  }
`

const Content = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: white;
	max-width: 1440px;
	margin: 0 auto;

	@media(max-width: 1260px) {
		flex-direction: column;
	}
	
	@media(max-width: 550px) {
		padding-top: 0px;
	}

	@media(max-width: 445px) {
		padding-top: 10px;
	}

	@media(max-width: 370px) {
		padding-top: 0;
	}
`

const TextWrapper = styled.div``

const Title = styled.h3`
	color: ${({ theme }) => theme.colors.fonts.dark};
	font-size: 40px;
	font-family: ${({ theme }) => theme.font.family.bebas};
	font-weight: 400;
	margin-bottom: 20px;
`

const Description = styled.p`
	max-width: 350px;
	font-family: ${({ theme }) => theme.font.family.circular};
	color: ${({ theme }) => theme.colors.fonts.dark};
	font-size: 16px;
	letter-spacing: 0px;
`

const Circle = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 300px;
	height: 300px;
	border-radius: 50%;
	border: 8px solid #aae828;
	box-shadow:  0 0 100px #48cc5e;
	margin-left: 200px;
	
	@media(max-width: 1260px) {
		margin: 100px 0 0;
		width: 250px;
		height: 250px;
  }
`

const CircleText = styled.div`
	font-family: ${({ theme }) => theme.font.family.bebas};
	font-size: 140px;
	background: -webkit-linear-gradient(#c5f01a, #48cc5e);
	-webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

	@media(max-width: 1362px) {
		font-size: 120px;
  }
`

const ThirdSection = () => (
	<Section>
		<Content>
			<TextWrapper>
				<Title>
					It starts with tracking
				</Title>
				<Description>
					MyArkeo is a fitness app. Much the same as Strava or Nike Run.
					The app acts as a record for performance.
				</Description>
				<Description>
					When running, you try and beat your previous time by training
					and making improvements to your form, technique and strength.
				</Description>
				<Description>
					Our mental fitness app highlights patterns and trends in your
					behaviour and tracks challenges. Then you can see how your everyday lifestyle choices are impacting your score.
				</Description>
			</TextWrapper>
			<Circle>
				<CircleText>
					78
				</CircleText>
			</Circle>
		</Content>
	</Section>
)

export default ThirdSection