import React from "react"
import styled from "styled-components"

import moneyIcon from 'assets/images/about/money-icon.png'
import avatarIcon from 'assets/images/about/avatar-icon.png'
import relationshipIcon from 'assets/images/about/relationship-icon.png'
import moodIcon from 'assets/images/about/mood-icon.png'
import exerciseIcon from 'assets/images/about/exercise-icon.png'
import foodIcon from 'assets/images/about/food-icon.png'

const tiles = [
	{
		src: moneyIcon,
		text: 'Money'
	},
	{
		src: avatarIcon,
		text: 'Focus'
	},
	{
		src: relationshipIcon,
		text: 'Relation-\ ships'
	},
	{
		src: moodIcon,
		text: 'Mood'
	},
	{
		src: exerciseIcon,
		text: 'exercise'
	},
	{
		src: foodIcon,
		text: 'Food'
	}
]

const Section = styled.section`
	background-color: white;
	padding: 40px 140px;

	@media(max-width: 520px) {
		padding: 40px 30px;
  }
`

const Content = styled.div`
	max-width: 1440px;
	margin: 0 auto;
`

const TextWrapper = styled.div`
	text-align: center;
`

const Title = styled.h3`
	color: ${({ theme }) => theme.colors.fonts.dark};
	font-size: 40px;
	font-family: ${({ theme }) => theme.font.family.bebas};
	font-weight: 400;
	margin: auto auto 40px auto;
`

const Description = styled.p`
	max-width: 500px;
	font-family: ${({ theme }) => theme.font.family.circular};
	color: ${({ theme }) => theme.colors.fonts.dark};
	font-size: 16px;
	letter-spacing: 0px;
	margin-left: auto;
	margin-right: auto;
	text-align: left;
`

const Tiles = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
`

const Tile = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-radius: 8px;
	box-shadow: 0 20px 20px rgba(0, 0, 0, 0.1), 6px 6px 12px rgba(0, 0, 0, 0.1);
	box-sizing: padding-box;
	width: 180px;
	height: 180px;
	margin: 20px;
	padding: 30px 0;

	img {
		width: 40%;
	}
`

const TileText = styled.div`
	color: ${({ theme }) => theme.colors.fonts.dark};
	font-size: ${({ entry }) => entry === 'Relation-\ ships' ? `${32}px` : `${40}px`};
	max-width: ${({ entry }) => entry === 'Relation-\ ships' ? `${120}px` : `${140}px`};
	font-family: ${({ theme }) => theme.font.family.bebas};
	font-weight: 400;
	line-height: 30px;
	margin-top: 20px;
	text-align: center;
`

const FourthSection = () => (
	<Section>
		<Content>
			<TextWrapper>
				<Title>
					Measure your mental fitness performance
				</Title>
				<Description>
					The MyArkeo app tracks behavioural and environmental factors i.e
					what you have done and then how you feel you have dealt with it.
				</Description>
				<Description>
					Most challenges and daily struggles are common and we all have universal
					worries. What’s different, is how well we deal and react to these phenomena. 
				</Description>
			</TextWrapper>
			<Tiles>
				{
					tiles.map(tile => (
						<Tile key={tile.text}>
							<img src={tile.src} alt="tile" />
							<TileText entry={tile.text}>
								{tile.text}
							</TileText>
						</Tile>
					))
				}
			</Tiles>
		</Content>
	</Section>
)

export default FourthSection