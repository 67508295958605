import React from "react"
import styled from "styled-components"

import watchImage from 'assets/images/about/arkeo-health-data-background.png'

const Section = styled.section``

const Image = styled.div`
	background-image: url(${watchImage});
	background-size: cover;
	background-position: right;
	background-repeat: no-repeat;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	min-height: 535px;
	padding-left: 140px;
	max-width: 1440px;
	margin: 0 auto;

	@media(min-width: 1210px) {
    justify-content: center;
  }

	@media(max-width: 1150px) {
    align-items: flex-start;
		background-position: 100px -220px;
		min-height: 400px;
  }

	@media(max-width: 940px) {
		min-height: 700px;
		background-position: -150px 150px;
  }

	@media(max-width: 780px) {
		padding: 0 60px;
		background-position: -320px 150px;
	}

	@media(max-width: 420px) {
		background-position: -400px 200px;
	}

	@media(max-width: 380px) {
		background-position: -420px 250px;
	}
`

const TextWrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 100px;

	@media(min-width: 1210px) {
    margin-right: 600px;
  }

	@media(max-width: 1150px) {
		margin-top: 0;
	}
`

const Description = styled.p`
	max-width: 450px;
	font-family: ${({ theme }) => theme.font.family.circular};
	font-size: 16px;
	letter-spacing: 0px;
	color: white;
	line-height: 25px;
`
const Title = styled.h3`
	font-family: ${({ theme }) => theme.font.family.bebas};
	font-weight: 400;
	font-size: 40px;
	color: white;
	margin-bottom: 20px;
`

const FifthSection = () => (
	<Section>
		<Image>
			<TextWrapper>
				<Title>
					Health data
				</Title>
				<Description>
					Connect your Apple watch, Fitbit or Samsung deviceto understand
					the peaks and troughs of how your heart rate has varied during challenging times. 
				</Description>
				<Description>
					This will give you deeper insights into your
					mental fitness and how your performance
					affects your overall mental fitness score.
				</Description>
			</TextWrapper>
		</Image>
	</Section>
)

export default FifthSection