import React from 'react'
import MainTemplate from 'templates/MainTemplate/MainTemplate'
import About from "components/About/About"

const AboutPage = () => (
  <MainTemplate title="Arkeo - About">
    <About />
  </MainTemplate>
)

export default AboutPage
