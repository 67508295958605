import React from "react"
import styled from "styled-components"
import Header from 'components/Header/Header'
import Footer from 'components/Footer/Footer'
import FirstSection from './FirstSection'
import SecondSection from './SecondSection'
import ThirdSection from './ThirdSection'
import FourthSection from './FourthSection'
import FifthSection from './FifthSection'

const AboutPageContent = styled.div`
	position: relative;
  z-index: 0;
	margin: auto 0;
`

const About = () => (
	<>
		<Header section="about" />
		<AboutPageContent>
			<FirstSection/>
			<SecondSection />
			<ThirdSection />
			<FourthSection />
			<FifthSection />
		</AboutPageContent>
		<Footer />
	</>
)

export default About
