import React from "react"
import styled from "styled-components"

import dashboardImage from 'assets/images/about/arkep-app-daily-dashboard.png'
import manImage from 'assets/images/about/arkeo-tracking-man-background.png'
import appImage from 'assets/images/about/arkeo-app-graphic.png'

const Section = styled.section`
	background-color: #f9f9f7;
`

const Image = styled.div`
	position: relative;
	background-color: #f9f9f7;
	background-image: url(${manImage});
	background-size: cover;
	background-position: left;
	background-repeat: no-repeat;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	min-height: 1300px;
	padding: 0 140px;
	margin: 0 auto;
	max-width: 1920px;

	@media(max-width: 1600px) {
		background-color: white;
	}

	@media(max-width: 1100px) {
		min-height: 900px;
	}

	@media(max-width: 780px) {
		min-height: 500px;
		padding: 0 60px;
	}

	@media(max-width: 445px) {
		min-height: 350px;
		padding: 0 20px;
  }

	@media(max-width: 445px) {
		align-items: flex-end;
  }
`

const Divider = styled.div`
	background-color: #f9f9f7;
	height: 400px;
	width: 100%;

	@media(max-width: 1600px) {
		background-color: white;
  }
`

const PhoneImage = styled.div`
	position: absolute;
	right: 30%;
	top: -45%;

	@media(max-width: 1100px) {
    top: -60%;
  }

	@media(max-width: 855px) {
		top: -55%;
		right: 5%;
		width: 45%;
  }

	@media(max-width: 780px) {
		top: -100%;
  }

	@media(max-width: 580px) {
		top: -95%;
		width: 50%;
  }

	@media(max-width: 540px) {
		top: -91%;
		width: 50%;
  }

	@media(max-width: 445px) {
		top: -140%;
  }

	@media(max-width: 370px) {
		top: -130%;
  }
`

const AppImage = styled.div`
	position: absolute;
	bottom: -150px;

	@media(max-width: 780px) {
		left: 10%;
		top: 25%;
		width: 45%;
	}

	@media(max-width: 570px) {
		left: 10%;
		top: 25%;
		width: 55%;
	}
	
	@media(max-width: 380px) {
		top: 50%;
		width: 60%;
	}
	
`

const Title = styled.h3`
	color: ${({ theme }) => theme.colors.fonts.dark};
	font-size: 40px;
	font-family: ${({ theme }) => theme.font.family.bebas};
	font-weight: 400;
	margin-bottom: -20px;

	@media(max-width: 445px) {
    font-size: 55px;
  }
`

const Description = styled.p`
	max-width: 350px;
	font-family: ${({ theme }) => theme.font.family.circular};
	color: ${({ theme }) => theme.colors.fonts.dark};
	font-size: 26px;
	font-weight: 500;
	letter-spacing: -1px;
	line-height: 35px;

	@media(max-width: 445px) {
    font-size: 24px;
		line-height: 30px;
  }
`
const TextWrapper = styled.div`
	@media(max-width: 1050px) {
    margin-top: 50px;
  }

	@media(max-width: 855px) {
    margin-top: 0px;
  }

	@media(max-width: 781px) {
    margin-top: -100px;
  }

	@media(max-width: 445px) {
    margin-top: -200px;
  }
`

const SecondSection = () => (
	<Section>
		<Divider />
		<Image>
			<PhoneImage>
				<img
					style={{ width: `${100}%` }}
					src={dashboardImage}
					alt="dashboard"
				/>
			</PhoneImage>
			<AppImage>
				<img
					style={{ width: `${100}%` }}
					src={appImage}
					alt="app-screenshot"
				/>
			</AppImage>
			<TextWrapper>
				<Title>
					Track it all
				</Title>
				<Description>
					Track every aspect of your life, 
					your feelings, your challenges 
					and your behaviours.
				</Description>
			</TextWrapper>
		</Image>
	</Section>
)

export default SecondSection